import React from 'react'
import { Dropdown, DropdownProps, DropdownItemProps, Flag, FlagNameValues } from 'semantic-ui-react'
import './CountrySelect.css'

interface Props {
  header: string
  loading: boolean
  onChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void
  options: DropdownItemProps[]
  placeholder: string
  value: string
}

interface CustomOpt {
  key: string
  value: string
  text: JSX.Element
}

const customSearch = (filteredOptions: DropdownItemProps[], searchQuery: string) =>
  filteredOptions.filter(
    opt =>
      (opt &&
        ((opt.text as JSX.Element).props.children[2] as string)
          .toLowerCase()
          .search(searchQuery.toLowerCase()) !== -1) ||
      (opt && (opt.value as string)).toLowerCase().search(searchQuery.toLowerCase()) !== -1
  )

const formatOptions = (options: DropdownItemProps[]) =>
  options.map(option => ({
    key: option.key,
    value: option.value,
    text: (
      <span>
        <Flag name={option.flag as FlagNameValues} />
        &nbsp;{option.text}
      </span>
    )
  }))

const CountrySelect: React.FC<Props> = ({
  header,
  loading,
  onChange,
  options,
  placeholder,
  value
}) => (
  <Dropdown
    header={header}
    className="country-select"
    placeholder={placeholder}
    search={customSearch}
    selection
    options={formatOptions(options)}
    value={value}
    selectOnNavigation={false}
    onChange={(event, data) => onChange(event, data)}
    loading={loading}
    disabled={options.length === 0 || loading}
  />
)

export default CountrySelect
