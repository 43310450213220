import React from 'react'
import { Loader } from 'semantic-ui-react'
import './RateResult.css'

interface Props {
  domesticRate: number | null
  treatyRate: number | null
  loading: boolean
}

const TreatyRateResult: React.FC<Props> = ({ domesticRate, treatyRate, loading }) => {
  return (
    <div className="rates">
      <Loader active={loading} size="large" />

      <div className={'rates__result ' + (domesticRate === null ? 'rates__hidden' : 'rates__show')}>
        Source rate:
        <span className="rates__result rates__result--source">&nbsp;{domesticRate}%</span>
      </div>

      <div className={'rates__result ' + (treatyRate === null ? 'rates__hidden' : 'rates__show')}>
        Treaty rate:
        <span className="rates__result rates__result--treaty">&nbsp;{treatyRate}%</span>
      </div>
    </div>
  )
}

export default TreatyRateResult
