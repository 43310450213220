import React from 'react'
import { DropdownProps } from 'semantic-ui-react'
import Title from './Title'
import CountrySelect from './CountrySelect'
import Disclaimer from './Disclaimer'
import RateResult from './RateResult'
import reducer from './reducer'
import fetchData from './fetchData'
import './App.css'

const initialState: State = {
  taxingState: '',
  taxingStates: [],
  taxingStatesLoading: false,
  partnerState: '',
  partnerStates: [],
  partnerStatesLoading: false,
  domesticRate: null,
  treatyRate: null,
  ratesLoading: false
}

const App: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    if (state.taxingStates.length === 0) {
      dispatch({ type: 'SET_TAXING_STATES_LOADING' })
      fetchData('/', data => {
        dispatch({ type: 'ADD_TAXING_STATES', payload: data })
      })
    }
  }, [state.taxingStates.length])

  React.useEffect(() => {
    if (state.taxingState !== '') {
      dispatch({ type: 'SET_PARTNER_STATES_LOADING' })
      fetchData(`/partners/${state.taxingState}`, data => {
        dispatch({ type: 'ADD_PARTNER_STATES', payload: data })
      })
    }
  }, [state.taxingState])

  React.useEffect(() => {
    if (state.taxingState !== '' && state.partnerState !== '') {
      dispatch({ type: 'SET_RATES_LOADING' })
      fetchData<ApiRates>(`/${state.taxingState}/${state.partnerState}`, data => {
        dispatch({ type: 'SET_RATES', payload: data })
      })
    }
  }, [state.taxingState, state.partnerState])

  const handleTaxingStateChange = React.useCallback((_, data: DropdownProps) => {
    typeof data.value === 'string' && dispatch({ type: 'SET_TAXING_STATE', payload: data.value })
  }, [])

  const handlePartnerStateChange = React.useCallback((_, data: DropdownProps) => {
    typeof data.value === 'string' && dispatch({ type: 'SET_PARTNER_STATE', payload: data.value })
  }, [])

  return (
    <div className="container">
      <Title title="Literary Royalty Tax Rates" />
      <div>
        <CountrySelect
          placeholder="Taxing State"
          header="The state that taxes the income"
          options={state.taxingStates}
          value={state.taxingState}
          onChange={handleTaxingStateChange}
          loading={state.taxingStatesLoading}
        />
        <CountrySelect
          placeholder={state.taxingState ? 'Treaty Partner' : 'Select a Taxing State first...'}
          header="Taxpayer tax residence"
          options={state.partnerStates}
          value={state.partnerState}
          onChange={handlePartnerStateChange}
          loading={state.partnerStatesLoading}
        />
      </div>
      <div className="containter__results">
        <RateResult
          domesticRate={state.domesticRate}
          treatyRate={state.treatyRate}
          loading={state.ratesLoading}
        />
      </div>
      <Disclaimer />
    </div>
  )
}

export default App
