import React from 'react'
import { Message } from 'semantic-ui-react'
import './Disclaimer.css'

const Disclaimer = () => (
  <div className="disclaimer">
    <Message floating className="disclaimer__text">
      <Message.Header>LEGAL DISCLAIMER</Message.Header>
      <p>
        The information made available by this app is for general information purposes only. It is
        provided by Graal Sp. z o.o. and we hope it will be useful to authors and fellow publishing
        industry professionals. While we endeavour to keep the information up to date and correct we
        make no guarantees about its completeness, accuracy, or reliability. Any reliance you place
        on such information is therefore at your own risk. In no event will we be liable for any
        loss or damage arising out of the use of this app.
      </p>
    </Message>
    <p className="disclaimer__text">
      Please email any corrections, comments, or suggestions to{' '}
      <a href="mailto:info@graal.com.pl">info@graal.com.pl</a>
    </p>
  </div>
)

export default Disclaimer
