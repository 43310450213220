import React from 'react'
import './Title.css'

interface Props {
  title: string
}

const Title: React.FC<Props> = ({ title }) => <h1 className="title">{title}</h1>

export default Title
