const apiURL = 'https://api.graalagency.com/tr'

export default async function fetchData<T = ApiStates[]>(url: string, cb: (data: T) => void) {
  try {
    const res = await fetch(apiURL + url)
    const data: T = await res.json()
    cb(data)
  } catch (error) {
    console.log(error)
  }
}
