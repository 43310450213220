export default function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'ADD_TAXING_STATES':
      return {
        ...state,
        taxingStates: action.payload.map(s => ({
          key: s.iso3,
          value: s.iso3,
          flag: s.iso2,
          text: s.name_en
        })),
        taxingStatesLoading: false
      }
    case 'SET_TAXING_STATE': {
      return {
        ...state,
        taxingState: action.payload,
        domesticRate: null,
        treatyRate: null,
        partnerState: ''
      }
    }
    case 'SET_TAXING_STATES_LOADING': {
      return {
        ...state,
        taxingStatesLoading: true
      }
    }
    case 'ADD_PARTNER_STATES':
      return {
        ...state,
        partnerStates: action.payload.map(s => ({
          key: s.iso3,
          value: s.iso3,
          flag: s.iso2,
          text: s.name_en
        })),
        partnerStatesLoading: false
      }
    case 'SET_PARTNER_STATE': {
      return {
        ...state,
        partnerState: action.payload,
        domesticRate: null,
        treatyRate: null
      }
    }
    case 'SET_PARTNER_STATES_LOADING': {
      return {
        ...state,
        partnerStatesLoading: true
      }
    }
    case 'SET_RATES': {
      return {
        ...state,
        domesticRate: action.payload.domestic_rate,
        treatyRate: action.payload.treaty_rate,
        ratesLoading: false
      }
    }
    case 'SET_RATES_LOADING': {
      return {
        ...state,
        ratesLoading: true
      }
    }
    default:
      throw new Error()
  }
}
